@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Lobster&family=Poppins:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Courgette&family=Lobster&family=Poppins:wght@100;400&display=swap');

@layer components {
	/* SEARCHRESULT.JS / CATEGORYPAGE.JS */

	.category-name {
		/* text-transform: uppercase; */
		padding: 0.5rem;
		border-radius: 0.5rem;
		text-align: center;
		background-color: #3498db;
		color: #fff;
		font-size: 1.2rem;
	}
	@media screen and (max-width: 768px) {
		.category-name {
			font-size: 1.2rem;
			padding: 0.4rem;
		}
	}

	@media screen and (max-width: 480px) {
		.category-name {
			font-size: 1rem;
			padding: 0.3rem;
		}
	}

	.back-home-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		padding: 0.5rem;
		border-radius: 0.5rem;
		text-align: center;
		background-color: #3498db;
		color: #fff;
		font-size: 1.2rem;
	}
	.back-home-button {
		background-color: #2980b9;
	}
	@media screen and (max-width: 768px) {
		.back-home-button {
			font-size: 1.2rem;
			padding: 0.4rem;
		}
	}

	@media screen and (max-width: 480px) {
		.back-home-button {
			font-size: 1rem;
			padding: 0.3rem;
		}
	}
}
